export const environment = {
  production: true,
  api: {
    scheme: 'http',
    url: "admin-preprod.clicnote.fr/api",
    publicAccess: {
      authToken: "efd738fd1532f603c2f874cb8c80eb546e3e41fbd59580002c13401716b49e65"
    }
  },
  publicServer: {
    url: 'http://admin-preprod.clicnote.fr',
    directories: {
      medias: '/medias'
    },
    storage: {
      size: {
        max: 100
      }
    }
  }
};


